import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ExternalLink } from "../assets/icons.jsx";
import projects from "../projects.json";
import ProjectDetail from "../Components/modal";
import Footer from "../Components/footer.jsx";
import Contact from "../Sections/contact.jsx";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      modalId: null,
      project: {},
    };
  }
  render() {
    return (
      <>
        <div className="container-md mt-3">
          <div className="row">
            {projects.map((project, index) => (
              <div className="col-sm-6 col-md-4 mb-4" key={index}>
                <Card>
                  <Card.Link
                    onClick={() =>
                      this.setState({
                        show: true,
                        modalId: index,
                        project: project,
                      })
                    }>
                    <Card.Img variant="top" alt={"Image for " + project.title} src={require(`../assets/images/${project.image}`)} loading="lazy" />
                  </Card.Link>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Card.Link
                          onClick={() =>
                            this.setState({
                              show: true,
                              modalId: index,
                              project: project,
                            })
                          }>
                          <Card.Title className="truncate truncate-1">{project.title}</Card.Title>
                        </Card.Link>
                      </Col>
                      <Col xs="auto">
                        <Button className="svg-icon-link" href={project.link} variant="light" size="sm" target="blank">
                          <ExternalLink />
                        </Button>
                      </Col>
                    </Row>
                    <Card.Text className="truncate truncate-2">{project.description.slice(0, 1).map((p) => p.paragraph)}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
          <ProjectDetail show={this.state.show} onHide={() => this.setState({ show: false })} project={this.state.project} />
        </div>
      </>
    );
  }
}

export default Projects;
