import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { ExternalLink } from "../assets/icons.jsx";

class ProjectDetail extends React.Component {
  render() {
    const project = this.props.project;
    return (
      <Modal {...this.props} keyboard={true} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {project.title}{" "}
            <Button className="svg-icon-link" href={project.link} variant="light" size="sm" target="blank">
              <ExternalLink />
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {project.image && <Image src={require(`../assets/images/${project.image}`) || require("../assets/images/fff.jpg")} alt={"Image for " + project.title} loading="lazy" />}
          <h5 className="mt-3">About the project</h5>
          {project.description &&
            project.description.map((d, index) => (
              <p className="m-0" key={index}>
                {d.paragraph}
              </p>
            ))}
          <h5 className="mt-3">Tools used</h5>
          <ul>
            {project.tools_used &&
              project.tools_used.map((t, index) => (
                <li key={index}>
                  <b>{t.title}: </b>
                  {t.description}
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    );
  }
}
export default ProjectDetail;
