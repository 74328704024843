import React from "react";
import { Row } from "react-bootstrap";

function Contact() {
  return (
    <section className="contact text-center">
      <div className="box">
        <Row>
          <div className="col-md m-auto">
            <h1 className="title">Start a project</h1>
          </div>
          <div className="col-md m-auto">
            <p>Interested in working together? We should queue up a chat. I’ll buy the coffee.</p>
          </div>
          <div className="col-md m-auto">
            <a className="btn is-button" href="mailto:imyogesh33@gmail.com">
              Let's do this
            </a>
          </div>
        </Row>
      </div>
    </section>
  );
}

export default Contact;
