import React from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Linkedin, Github, Email } from "../assets/icons.jsx";

// images
import avatar from "../assets/images/image.jfif";

const Footer = () => {
  return (
    <footer className="footer text-center">
      <div className="container">
        <Col>
          <Image src={avatar} alt="Matt Farley | Designer, Front-end Developer &amp; Mentor" loading="lazy" />
        </Col>
        <Col>
          <h1 className="title">Living, learning, &amp; leveling up one day at a time.</h1>
        </Col>
        <Col>
          <Button variant="light" className="is-button" href="https://www.linkedin.com/in/vattujan/" target="_blank">
            <Linkedin />
          </Button>
          <Button variant="light" className="is-button" href="https://github.com/vattujan" target="_blank">
            <Github />
          </Button>
          <Button variant="light" className="is-button" href="mailto:imyogesh33@gmail.com" target="_blank">
            <Email />
          </Button>
        </Col>
        <Col>
          <p className="subtitle">Designed by Yogesh Bhattarai</p>
        </Col>
      </div>
    </footer>
  );
};

export default Footer;
