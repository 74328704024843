import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Card, Col, Row, Image } from "react-bootstrap";

// images
import avatar from "../assets/images/image.jfif";
import hero from "../assets/hero.svg";
import frontend from "../assets/frontend.svg";
import { ExternalLink } from "../assets/icons.jsx";
import projects from "../projects.json";
import ProjectDetail from "../Components/modal";
import skills from "../skills.json";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      modalId: null,
      project: {},
    };
  }

  render() {
    return (
      <>
        {/* Hero Section */}
        <section className="hero text-center">
          <div>
            <h1 className="title">Front-end &amp; Back-end Developer</h1>
            <h2 className="subtitle">I believe in minimal design and well structured code.</h2>
            <Image className="avatar" alt="avatar" title="Yogesh Bhattarai" src={avatar} loading="lazy" />
          </div>
          <div>
            <Image className="hero" alt="hero" src={hero} loading="lazy" />
          </div>
        </section>

        {/* Intro section */}
        <section className="intro text-center">
          <div className="is-dark-background">
            <div className="container">
              <h1 className="title">
                Hi, I’m Yogesh
                <span role="img" aria-label="Emoji">
                  {" "}
                  👋
                </span>
                .
              </h1>
              <h2 className="subtitle">I am a Web developer able to build a Web presence from the ground up - from concept, navigation, layout, and programming to UX. Skilled at writing well-designed, testable and efficient code using current best practices in Web development. Fast learner, hard worker and team player who is proficient in an array of scripting languages and multimedia Web tools.</h2>
            </div>
          </div>
        </section>

        {/* Skills section */}
        <section className="skills text-center">
          <div className="card">
            <div className="row m-0">
              <div className="col-sm-6 skills-col">
                <figure>
                  <Image alt="skill icon" src={frontend} loading="lazy" />
                </figure>
                <h1 className="title">Frontend Developer</h1>
                <p>I like to code things from scratch, and enjoy bringing ideas to life in the browser.</p>
                <p className="list-title">Languages I speak:</p>
                <p>HTML, CSS, JavaScript, React, Angular</p>
                <p className="list-title">Dev Tools:</p>
                <ul>
                  <li>Github</li>
                  <li>Codepen</li>
                  <li>Chrome DevTools</li>
                </ul>
              </div>
              <div className="col-sm-6 skills-col">
                <figure>
                  <Image alt="skill icon" src={frontend} loading="lazy" />
                </figure>
                <h1 className="title">Backend Developer</h1>
                <p>I love giving structure to an idea, and add logic to make the web app function as aspected.</p>
                <p className="list-title">Languages I speak:</p>
                <p>C#, ASP.NET, Node.js, Mysql </p>
                <p className="list-title">Dev Tools:</p>
                <ul>
                  <li>Mysql Workbench</li>
                  <li>Git</li>
                  <li>Postman</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="skills-images text-center">
          <h1 className="title text-truncate">Skills</h1>
          <Container>
            <ul className="skills-grid">
              {skills.map((skill, index) => (
                <li key={index} className="skill-col m-2">
                  <div className="skill-card">
                    <div className="skill-images">
                      <Image className={"skill-" + skill.name.toLowerCase()} alt={skill.name} src={require(`../assets/icons/${skill.image ? skill.image : "na.png"}`)} loading="lazy" />
                    </div>
                    <p className="small mb-0">{skill.name}</p>
                  </div>
                </li>
              ))}
            </ul>
          </Container>
        </section>

        {/* Projects section */}
        <section className="projects text-center">
          <Container fluid="lg">
            <h1 className="title text-truncate">My Recent Work</h1>
            <h2 className="subtitle">
              Here are a few design projects I've worked on recently. Want to see more?
              <a href="mailto:imyogesh33@gmail.com"> Email me</a>.
            </h2>
            <div className="project-grid text-left">
              <div className="row">
                {projects.slice(0, 3).map((project, index) => (
                  <div className="col-sm-6 col-md-4 mb-4" key={index}>
                    <Card>
                      <Card.Link
                        onClick={() =>
                          this.setState({
                            show: true,
                            modalId: index,
                            project: project,
                          })
                        }>
                        <Card.Img variant="top" alt={"Image for " + project.title} src={require(`../assets/images/${project.image}`)} loading="lazy" />
                      </Card.Link>

                      <Card.Body>
                        <Row>
                          <Col>
                            <Card.Link
                              onClick={() =>
                                this.setState({
                                  show: true,
                                  modalId: index,
                                  project: project,
                                })
                              }>
                              <Card.Title className="truncate truncate-1">{project.title}</Card.Title>
                            </Card.Link>
                          </Col>
                          <Col xs="auto">
                            <Button className="svg-icon-link" href={project.link} variant="light" size="sm" target="blank">
                              <ExternalLink />
                            </Button>
                          </Col>
                        </Row>
                        <Card.Text className="truncate truncate-2">{project.description.slice(0, 1).map((p) => p.paragraph)}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
            <Link className="btn is-button background-white" to="/projects">
              See all projects
            </Link>
            <ProjectDetail show={this.state.show} onHide={() => this.setState({ show: false })} project={this.state.project} />
          </Container>
        </section>
      </>
    );
  }
}

export default Home;
