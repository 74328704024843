import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NavBar from './Components/navBar';
import Home from "./Pages/home";
import NotFound from "./Pages/notFound";
import Projects from './Pages/projects';
import Footer from "./Components/footer";
import Contact from "./Sections/contact";

function App() {
  return (
    <>
      <NavBar />

      <Switch>
        <Route path="/projects" component={Projects} />
        <Route path="/" component={Home} exact />
        <Route path="*" component={NotFound} />
      </Switch>

      <Contact />
      <Footer />
    </>
  )
}

export default App;
