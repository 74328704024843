import React from "react";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar.svg";

const pages = [
  {
    title: "Projects",
    href: "/projects",
    icon: <></>,
  },
];
class NavBar extends React.Component {
  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-md">
            <Link className="nav-item navbar-brand" to="/">
              <img src={avatar} width="30" height="30" alt="logo" /> Vattujan
            </Link>
            <div className="navbar-nav ml-auto">
              {pages.map((page) => (
                <Link
                  className="nav-item nav-link"
                  key={page.title}
                  to={page.href}
                >
                  {page.icon} {page.title}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default NavBar;
